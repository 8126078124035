import { useEffect, useState } from "react";
import { ContentWrapper, FormWrapper, InnerContentWrapper } from "./styled";
import Title from "../../../library/title";
import {IdToken, useAuth0 } from "@auth0/auth0-react";
import useConfiguration from "../../../hooks/useConfiguration";
import { perfomWhatAuthorized } from "./data";

const Content = () => {
  const { configurations } = useConfiguration();
  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
    isLoading,
     getAccessTokenSilently,
  } = useAuth0();
  const [idToken, setIdToken] = useState<IdToken | undefined>();
  const [accessToken, setAccessToken] = useState<string>();
  const [selectedCredential, setSelectedCredential] = useState<any>({});
  const [isIngesting, setIsIngesting] = useState(false);

  // useEffect(() => {
  //   const fetchTokens = async () => {
  //     try {
  //       if (!isAuthenticated) {
  //         return;
  //       }
  //       const idTokenClaims = await getIdTokenClaims();
  //       const accessToken = await getAccessTokenSilently();
        
  //       console.log('ID Token:', idTokenClaims?.__raw);
  //       console.log('Access Token:', accessToken);
  //     } catch (error) {
  //       console.error('Error fetching tokens:', error);
  //     }
  //   };

  //   fetchTokens();
  // }, [getIdTokenClaims, getAccessTokenSilently, isAuthenticated]);

  const ingestToken = async (e: any) => {
    const formData = {
      credential: selectedCredential,
      token: idToken?.__raw,
      // token: accessToken,
    };

    setIsIngesting(true);
    await perfomWhatAuthorized(formData);
    setIsIngesting(false);
  };

  useEffect(() => {
    if (configurations.length > 0) {
      setSelectedCredential(configurations[0]?.value);
    }
  }, [configurations]);

  useEffect(() => {
    const tokenClaims = async () => {
      const idToken = await getIdTokenClaims();
      setIdToken(idToken);
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
    };
    if (isAuthenticated) {
      tokenClaims().catch(console.error);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const LoginButton = () => {
    return (
      <button
        className="flex-shrink bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              agentToken: JSON.parse(selectedCredential)?.token,
              baseUrl: JSON.parse(selectedCredential)?.baseUrl,
              profilePicker: window.location.origin + "/profile-picker",
            },
          })
        }
      >
        Log In
      </button>
    );
  };

  const LogoutButton = () => {
    return (
      <button
        className="flex-shrink bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300 max-w-[100px]"
        onClick={() =>
          logout({
            logoutParams: { returnTo: window.location.origin + "/login" },
          })
        }
      >
        Log Out
      </button>
    );
  };

  const IngestButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300 max-w-[200px]"
        onClick={ingestToken}
      >
        {isIngesting ? (
          <div className="flex gap-1 items-center justify-center">
            <i className="fas fa-spinner fa-spin"></i>
            Introspecting...
          </div>
        ) : (
          <div className="flex justify-center">Token ingest</div>
        )}
      </button>
    );
  };

  const Configuration = () => {
    return (
      <div>
        <label
          htmlFor="credential"
          className="text-white font-raleway font-bold"
        >
          Configuration
        </label>
        <div>
          <select
            className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
            id="credential"
            name="credential"
            onChange={(e) => {
              setSelectedCredential(e.target.value);
            }}
            value={selectedCredential}
          >
            {configurations.map((t) => (
              <option key={t.id} value={t.value}>
                {t.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const Profile = () => {
    if (isLoading) {
      return <div>Loading ...</div>;
    }

    return (
      isAuthenticated && (
        <div>
          <b className="text-white">Token info</b>
          <pre className="text-white rounded-md border-none bg-[#222222] px-3 py-3">
            {JSON.stringify(user, null, 2)}
          </pre>
          <div className="text-white break-all rounded-md border-none bg-[#222222] px-3 py-3">
            {accessToken}
          </div>
          {/* <div className="text-white break-all rounded-md border-none bg-[#222222] px-3 py-3">
            {idToken?.__raw}
          </div> */}
        </div>
      )
    );
  };

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>Login</Title>
        <FormWrapper>
          <div className="mb-2">
            <Configuration />
          </div>
          {!isAuthenticated && <LoginButton />}
          {isAuthenticated && (
            <div className="flex justify-between">
              <LogoutButton />
              <IngestButton />
            </div>
          )}
          <Profile />
        </FormWrapper>
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Content;
