import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth0Provider } from "@auth0/auth0-react";

export const AUTH0_DOMAIN = "1st.eu.auth0.com";
const CLIENT_ID = "N6VibJHjxIpKEvkBKSWU1xLxh6xnca55"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin + '/login',
      audience: CLIENT_ID,
      scope: "openid profile email",
    }}
    cacheLocation="localstorage" // Persist tokens in local storage
    useRefreshTokens={true} // Enable refresh tokens
  >
    <React.StrictMode>
      <Routes />
      <ToastContainer />
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
