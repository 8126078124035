import { toast } from "react-toastify";

const WHAT_ENDPOINT =
  "/indykite.authorization.v1beta1.AuthorizationAPI/WhatAuthorized";

export const perfomWhatAuthorized = async (data: any): Promise<void> => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const token: string = data.token;
    if (!token) {
      toast("invalid token", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const payload = {
      subject: {
        accessToken: `${token}`,
      },
      resource_types: [
        {
          type: `Person`,
          actions: [`EXISTS`],
        },
      ],
    };

    const url = baseUrl + WHAT_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Could not read data.");
    }
    toast("Token introspected and ingested", {
      type: "success",
      position: "top-right",
    });
  } catch (e) {
    console.error(e);
    toast("Error performing authorization", {
      type: "error",
      position: "top-right",
    });
  }
};
